'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import TooltipText from '../Widgets/Common/TooltipText.react';
import MacrosPieChart from '../../components/Widgets/MacrosPieChart.react';
import { getNutrientsToDisplay } from '../../pro/utils/Patients';
import { roundForHumans } from '../../utils/Math';
import nutrKeys from '../../tables/nutrient-order';
import allNutrients from '../../tables/nutrients';

import '../MyAccount/PreferencesForm.scss';
import './NutritionInfo.scss';

const nutrientOverrides = {
    '208': {
        NutrDesc: 'Calories',
        Units: '',
    },
}

export default class NutritionInfo extends Component {
    static propTypes = {
        nutrients: PropTypes.object,
        profile: PropTypes.object,
        showAllAvailable: PropTypes.bool,
        title: PropTypes.string,
    };


    renderFodmapValue = (percentage) => {
        let fodmapValue = "LOW FODMAP";
        let color = "fodmap-friendly";

        if (percentage > 25) {
            fodmapValue = "MODERATE FODMAP";
            color = "fodmap-tolerable";         
        }

        if (percentage > 50) {
            fodmapValue = "HIGH FODMAP";
            color = "not-fodmap-friendly";         
        }

        return <p className="fodmap" data-fodmap={color}>{fodmapValue}</p>
    }

    render() {
        const { nutrients, profile, showAllAvailable } = this.props;

        const toDisplay = getNutrientsToDisplay(profile, nutrients, null, showAllAvailable);
        const nutrNos = Object.keys(toDisplay).sort((a, b) => {
            const aI = nutrKeys.indexOf(a),
                  bI = nutrKeys.indexOf(b);

            if (aI > bI) return 1;
            if (aI < bI) return -1;
            return 0;
        });

        const toDisplayNutrValues = {};

        Object.keys(toDisplay).forEach(nutrNo => {
            toDisplayNutrValues[nutrNo] = toDisplay[nutrNo].v;
        });

        return (
            <div className="nutrition-info">
                <header>
                    <MacrosPieChart nutrients={toDisplayNutrValues} />
                </header>

                <div className="display-list">
                    {nutrNos.map(nutrNo => {
                        const { t, v, trackedOnly, targetName } = toDisplay[nutrNo];
                        const nutrDef = nutrientOverrides[nutrNo] || allNutrients[nutrNo];

                        if (!nutrDef) {
                            return;
                        }

                        const percentage = t > 0 ? Math.round(v / t * 100) : 0;

                        const isFodmap = nutrDef.NutrDesc.includes("FODMAP");
 
                        return (
                            <div key={nutrNo} className="display-row">
                                <div>
                                    <div className="amt">
                                        <span>
                                            {typeof v == 'number' ? roundForHumans(v) : <span>&mdash;</span>} {nutrDef.Units}
                                        </span>
                                        <em><TooltipText text={nutrDef.NutrDesc} /></em>
                                    </div>
                                    {!isFodmap && !trackedOnly && targetName && typeof v === 'number' ? <p className="per">{percentage}% of {targetName}</p> : null}
                                </div>
                                {!isFodmap && targetName ?
                                    <div className="bar">
                                        <div className="fill" style={{width: (percentage <= 100 ? percentage : 100)+ '%'}}>&nbsp;</div>
                                    </div>
                                : null}
                                {isFodmap ?
                                        this.renderFodmapValue(percentage)
                                : null}

                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

